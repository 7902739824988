import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import {
  BetterThanOther,
  MultiColumnList,
  SolutionAndServices,
  ServiceCatalogues,
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class MediaAndEntertainment extends Component {
  render() {
    const mediaAndEntertainment = pageContent.subPage.mediaAndEntertainment;

    return (
      <Layout>
        <PageHeader image={mediaAndEntertainment.headerImage} />

        <Heading
          title={mediaAndEntertainment.title}
          overview={mediaAndEntertainment.overview}
          noHeadingIcon={true}
        />

        <BetterThanOther content={mediaAndEntertainment.betterThanOther} />

        <ServiceCatalogues items={mediaAndEntertainment.serviceCatalogues} />

        <MultiColumnList content={mediaAndEntertainment.features} />

        <SolutionAndServices solutions={mediaAndEntertainment.solutions} />

        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs />
      </Layout>
    );
  }
}

export default MediaAndEntertainment;
